import React, { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeScreen from '../pages/HomeScreen';
import Authentification from '../pages/Authentification';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../i18n';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute('content', 'width=device-width, initial-scale=0.4');
    }
  }, []);

  const queryClient = new QueryClient();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <div className="fixed top-24 right-4 z-50 flex gap-2">
          <button onClick={() => changeLanguage('en')} className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">EN</button>
          <button onClick={() => changeLanguage('fr')} className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">FR</button>
          <button onClick={() => changeLanguage('ar')} className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">عربي</button>
        </div>
        <Suspense fallback={<div> loading ... </div>}>
          <Routes>
            <Route path="/*" element={<HomeScreen />} />
            <Route path="/auth" element={<Authentification />} />
          </Routes>
        </Suspense>
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
