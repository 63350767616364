import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { FadeInOutWithOpacity, hoverredInnercard, scaleInOut } from '../animations';
import { BiFolderPlus, BiHeart, BiSolidFolderPlus, BiSolidHeart } from 'react-icons/bi';
import { saveToCollections, saveToFavorites } from '../api';
import useUser from '../hooks/useUser';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useTemplates from '../hooks/useTemplates';

function TemplateDesignPin({ data, index }) {
  const { t } = useTranslation();
  const { data: user, refetch: userRefetch } = useUser();
  const { refetch: temp_refetch } = useTemplates();
  const navigate = useNavigate();
  const [isHoverred, setIsHoverred] = useState(false);

  const addToCollection = async (e) => {
    e.stopPropagation();
    if (!user) {
      toast.error(t('loginRequired'));
      navigate('/auth');
      return;
    }
    await saveToCollections(user, data);
    userRefetch();
  };

  const addToFavorites = async (e) => {
    e.stopPropagation();
    if (!user) {
      toast.error(t('loginRequired'));
      navigate('/auth');
      return;
    }
    await saveToFavorites(user, data);
    temp_refetch();
  };

  const handleRouteNavigation = () => {
    if (!user) {
      toast.error(t('loginRequired'));
      navigate('/auth');
      return;
    }
    navigate(`/resumeDetail/${data?._id}`, { replace: true });
  };

  return (
    <motion.div key={data?._id} {...scaleInOut}>
      <div
        onMouseEnter={() => setIsHoverred(true)}
        onMouseLeave={() => setIsHoverred(false)}
        onClick={handleRouteNavigation}
        className="w-full h-[500px] z-0 2xl:h-[740px] rounded-md overflow-hidden relative"
      >
        <img
          src={data?.imageURL}
          alt="Template"
          className="w-full h-[80%] object-contain bg-indigo-200 py-2 px-2"
        />



        <AnimatePresence>
          {isHoverred && (
            <motion.div {...FadeInOutWithOpacity} className="absolute h-[80%] inset-0 bg-[rgba(0,0,0,0.4)] flex flex-col items-center justify-start px-3 py-3 z-50 cursor-pointer">
              <div className="flex flex-col items-end justify-start w-full gap-4">
                <InnerBoxCard 
                  label={user?.collections?.includes(data?._id) ? "in your collections" : "Add to collections"} 
                  Icon={user?.collections?.includes(data?._id) ? BiSolidFolderPlus : BiFolderPlus} 
                  onHandle={addToCollection} 
                />
                <InnerBoxCard 
                  label={data?.favorites?.includes(user?.uid) ? "you liked" : "like this template"} 
                  Icon={data?.favorites?.includes(user?.uid) ? BiSolidHeart : BiHeart}  
                  onHandle={addToFavorites} 
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

const InnerBoxCard = ({ label, Icon, onHandle }) => {
  const [isHoverred, setIsHoverred] = useState(false);

  return (
    <div
      onClick={onHandle}
      onMouseEnter={() => setIsHoverred(true)}
      onMouseLeave={() => setIsHoverred(false)}
      className="w-10 h-10 rounded-md bg-gray-300 flex items-center justify-center hover:shadow-md relative"
    >
      <Icon className="text-txtPrimary text-base" />
      {isHoverred && (
        <AnimatePresence>
          <motion.div
            {...hoverredInnercard}
            className="px-3 capitalize py-2 rounded-md bg-gray-200 absolute -left-44 after:w-2 after:h-2 after:bg-gray-200 after:absolute after:-right-1 after:top-[14px] after:rotate-45"
          >
            <p className="text-sm text-txtPrimary whitespace-nowrap">{label}</p>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default TemplateDesignPin;
