import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    navigate('/auth');
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-3xl font-semibold text-center mb-6">{t('contactUs')}</h1>

        <div className="space-y-4 mb-6">
          <p>
            <strong className="mr-2">{t('email')}:</strong> cvdzbuilder@gmail.com
          </p>
          <p>
            <strong>{t('address')}:</strong> Constantine, Algeria
          </p>
        </div>

        <button
          onClick={handleBackClick}
          className="w-full mt-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
        >
          {t('goBack')}
        </button>
      </div>
    </div>
  );
};

export default Contact;
