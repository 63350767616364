import React, { useEffect } from 'react';
import { Filters, MainSpinner, TemplateDesignPin } from '../components';
import useTemplates from '../hooks/useTemplates';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useFilters from '../hooks/useFilters';
import { useTranslation } from 'react-i18next';

const HomeContainer = () => {
  const { t } = useTranslation();
  const {
    data: templates,
    isError: temp_isError,
    isLoading: temp_isLoading,
  } = useTemplates();

  const { data: filterData } = useFilters();
  const searchTerm = filterData?.searchTerm || '';

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7769481438437452";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      // Cleanup to remove script if the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  if (temp_isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <MainSpinner />
      </div>
    );
  }

  // Log the searchTerm and templates for debugging
  //console.log('Search Term:', searchTerm);
  //console.log('Templates:', templates);

  // Filter templates based on the first letter of the searchTerm
  const filteredTemplates = searchTerm
    ? templates.filter((template) =>
        template.tags &&
        template.tags.some((tag) =>
          tag.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
      )
    : templates; // If searchTerm is empty, return all templates

  

  return (
    <div className="w-full px-6 lg:px-16 py-10 flex flex-col items-center justify-start min-h-screen bg-gradient-to-r z-0 from-gray-100 via-white-100 to-biolet-100">
     
     <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7769481438437452"
        data-ad-slot="1192360459" // Replace with your ad slot ID
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
      <script>
        {`(adsbygoogle = window.adsbygoogle || []).push({});`}
      </script>
      {/* Hero Section with Link */}
      <div className="w-full max-w-xl text-center mb-12">
        <motion.h1
          className="text-4xl font-extrabold text-gray-900 mb-4"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          {t('findTemplate')}
        </motion.h1>
        <p className="text-lg text-gray-700">
          {t('browseCollection')}
        </p>
        <p className="text-sm capitalize mt-4 text-black">
          💖 constantine 💖 
        </p>
      
      </div>

      <Link className="w-full max-w-xs mb-8" to="/resize-image">
        <p className="text-gray-600 capitalize text-center">
          {t('resizeImage')}
        </p>
        <p className="rounded-md text-center bg-gradient-to-r from-blue-500 to-indigo-600 my-3 p-4 text-white font-semibold shadow-lg hover:shadow-xl transition-transform transform hover:scale-105">
          {t('resizeImage')}
        </p>
      </Link>

      {/* Filter Section */}
      <Filters />

      {/* Templates Display */}
      {temp_isError ? (
        <p className="text-lg text-red-600 mt-8">Something went wrong... Please try later.</p>
      ) : (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 mt-12">
          <RenderATemplate templates={filteredTemplates} />
        </div>
      )}
    </div>
  );
};

const RenderATemplate = ({ templates }) => {
  return (
    <>
      {templates && templates.length > 0 ? (
        <AnimatePresence>
          {templates.map((template, index) => (
            <TemplateDesignPin
              key={template?._id}
              data={template}
              index={index}
              className="transform hover:scale-105 transition-transform duration-300"
            />
          ))}
        </AnimatePresence>
      ) : (
        <p className="text-lg text-gray-600 mt-8">No templates found</p>
      )}
    </>
  );
};

export default HomeContainer;
