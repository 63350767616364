import React, { useState } from 'react';
import useUser from '../hooks/useUser';
import { Link } from 'react-router-dom';
import { Logo } from '../assets';
import { PuffLoader } from 'react-spinners';
import { AnimatePresence, motion } from 'framer-motion';
import { HiLogout } from 'react-icons/hi';
import { FadeInOutWithOpacity, slideUpDownMenu } from '../animations';
import { useQueryClient } from 'react-query';
import { auth } from '../config/firebase.config';
import { adminIds } from '../utils/helpers';
import useFilters from '../hooks/useFilters';
import { useTranslation } from 'react-i18next';

function Header() {
  const { data, isLoading } = useUser();
  const [isMenu, setIsMenu] = useState(false);
  const { data: filterData } = useFilters();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const signOutUser = async () => {
    await auth.signOut().then(() => {
      queryClient.setQueryData('user', null);
    });
  };

  const handleSearchTerm = (e) => {
    queryClient.setQueryData('globalFilter', {
      ...queryClient.getQueryData('globalFilter'),
      searchTerm: e.target.value,
    });
  };

  const clearFilter = () => {
    queryClient.setQueryData('globalFilter', {
      ...queryClient.getQueryData('globalFilter'),
      searchTerm: '',
    });
  };

  return (
    <header
      className="w-full bg-slate-300 flex items-center justify-between px-4 py-3 lg:px-8
     border-b border-gray-300 z-40 gap-12 sticky top-0"
    >
      <Link to={'/'}>
        <img src={Logo} className="w-12 h-auto object-contain" alt="" />
      </Link>

      {/* Search Bar */}
      <div
        className="flex-1 border border-gray-300 px-4 py-1 rounded-md
 flex items-center justify-between bg-gray-200"
      >
        <input
          type="text"
          value={filterData?.searchTerm ? filterData?.searchTerm : ''}
          placeholder={t('searchHere')}
          className="flex-1 h-10 bg-transparent 
text-base font-semibold outline-none border-none"
          onChange={handleSearchTerm}
        />

        <AnimatePresence>
          {filterData?.searchTerm?.length > 0 && (
            <motion.div
              {...FadeInOutWithOpacity}
              className="w-8 h-8 flex items-center justify-center bg-gray-300 rounded-md
    cursor-pointer active:scale-95 duration-150"
              onClick={clearFilter}
            >
              <p className="text-2xl text-black">x</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Navigation Links */}
      <div className="flex items-center gap-6">
        <Link to="/how-use" className="text-white hover:text-txtDark text-xl">
          <p className='capitalize bg-blue-900 p-3 rounded-md z-10'>{t('howToUse')}</p>
        </Link>
      </div>

      {/* User Profile Section */}
      <AnimatePresence>
        {isLoading ? (
          <PuffLoader color="#498FCD" size={40} />
        ) : (
          <React.Fragment>
            {data ? (
              <motion.div
                {...FadeInOutWithOpacity}
                className="relative"
                onClick={() => setIsMenu(!isMenu)}
              >
                {data?.photoURL ? (
                  <div
                    className="w-12 h-12 rounded-md relative flex items-center cursor-pointer
      justify-center"
                  >
                    <img
                      src={data?.photoURL}
                      className="w-full h-full object-cover rounded-md"
                      alt={t('userPhoto')}
                      referrerPolicy="no-referrer"
                    />
                  </div>
                ) : (
                  <div
                    className="w-12 h-12 rounded-md relative flex items-center cursor-pointer
      justify-center bg-blue-700 shadow-md"
                  >
                    <p className="text-white text-lg">{data?.email[0]}</p>
                  </div>
                )}

                <AnimatePresence>
                  {isMenu && (
                    <motion.div
                      {...slideUpDownMenu}
                      className="absolute px-4 py-3 rounded-md bg-white right-0 top-14 flex flex-col items-start justify-start gap-3 w-64 pt-6"
                    >
                      {adminIds.includes(data?.uid) && (
                        <Link
                          to={`/template/create`}
                          className="text-txtPrimary text-lg hover:text-blue-700"
                        >
                          {t('createTemplate')}
                        </Link>
                      )}

                      <Link
                        to={`/profile/${data?.uid}`}
                        className="text-txtPrimary text-lg hover:text-blue-700"
                      >
                        {t('profile')}
                      </Link>

                      <div
                        className="w-full px-2 py-2 border-t border-gray-300 flex items-center justify-between group cursor-pointer"
                        onClick={signOutUser}
                      >
                        <p className="group-hover:text-blue-700 text-txtPrimary">
                          {t('signOut')}
                        </p>
                        <HiLogout className="group-hover:text-blue-700 text-txtPrimary" />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ) : (
              <Link to="/auth">
                <motion.div
                  {...FadeInOutWithOpacity}
                  className="px-4 py-2 rounded-md border border-gray-300 flex items-center justify-center gap-3 hover:bg-gray-200 cursor-pointer"
                >
                  <p className="text-txtPrimary">{t('auth')}</p>
                </motion.div>
              </Link>
            )}
          </React.Fragment>
        )}
      </AnimatePresence>
    </header>
  );
}

export default Header;
