import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getTemplateDetails } from '../api';
import { MainSpinner, TemplateDesignPin } from '../components';
import { FaHouse } from 'react-icons/fa6';
import { BiFolderPlus, BiHeart, BiSolidFolderPlus, BiSolidHeart } from 'react-icons/bi';
import useUser from '../hooks/useUser';
import { saveToCollections, saveToFavorites } from '../api';
import useTemplates from '../hooks/useTemplates';
import { AnimatePresence } from 'framer-motion';
import { RiArrowGoBackFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

function TemplateDesignPinDetail() {
  const { templateID } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const { data, isError, isLoading, refetch } = useQuery(
    ['template', templateID],
    () => getTemplateDetails(templateID)
  );

  const { data: user, refetch: userRefetch } = useUser();
  const { data: templates, refetch: temp_refetch } = useTemplates();

  const addToCollection = async (e) => {
    e.stopPropagation();
    await saveToCollections(user, data);
    userRefetch();
  };

  const addToFavorites = async (e) => {
    e.stopPropagation();
    await saveToFavorites(user, data);
    temp_refetch();
    refetch();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <MainSpinner />;

  if (isError) return (
    <div className='w-full h-[60vh] flex flex-col items-center justify-center'>
      <p className='text-lg text-txtPrimary font-semibold'>{t('somethingWentWrong')}</p>
    </div>
  );

  return (
    <div className='w-full flex justify-start items-center flex-col px-8 py-6 bg-gradient-to-r from-blue-100 via-indigo-100 to-purple-100'>
      <div className='w-full flex items-center pb-8 gap-2'>
        <Link to={"/"} className='flex items-center justify-center gap-2 text-txtPrimary'>
          <FaHouse /> {t('home')}
        </Link>
        <p>/</p>
        <p>{data?.name}</p>
      </div>
      <Link to={"/"} className='flex flex-col items-center justify-center gap-2 text-txtPrimary'>
        <RiArrowGoBackFill className='text-5xl -mt-12 font-bold text-blue-600 mx-auto text-center'/>
        <h2 className='font-bold text-3xl capitalize'>{t('backHome')}</h2> 
      </Link>

      <div className='w-full grid grid-cols-1 lg:grid-cols-12'>
        <div className='col-span-1 lg:col-span-8 flex flex-col items-start justify-start gap-4'>
          <img src={data?.imageURL} alt='' className='w-full h-auto bg-indigo-200 p-8 object-contain rounded-md' />

          <div className='w-full flex flex-col items-start justify-start gap-2'>
            <div className='w-full flex items-center justify-between'>
              <p className='text-base text-txtPrimary font-semibold'>{data?.title}</p>

              {data?.favorites?.length > 0 && (
                <div className='flex items-center justify-center gap-1'>
                  <BiSolidHeart className='text-base text-red-500' />
                  <p className='text-base text-txtPrimary font-semibold'>{data?.favorites?.length} {t('likes')}</p>
                </div>
              )}
            </div>

            {user && (
              <div className='flex items-center justify-center gap-3 my-2'>
                {user?.collections?.includes(data?._id) ? (
                  <div onClick={addToCollection} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiSolidFolderPlus className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>{t('removeFromCollections')}</p>
                  </div>
                ) : (
                  <div onClick={addToCollection} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiFolderPlus className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>{t('addToCollections')}</p>
                  </div>
                )}

                {data?.favorites?.includes(user?.uid) ? (
                  <div onClick={addToFavorites} className='flex items-center justify-center  px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiSolidHeart className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>{t('youLikedTemplate')}</p>
                  </div>
                ) : (
                  <div onClick={addToFavorites} className='flex items-center justify-center px-4 py-2 rounded-md border border-gray-300 gap-2 hover:bg-gray-200 cursor-pointer'>
                    <BiHeart className="text-base text-txtPrimary" />
                    <p className='text-sm text-txtPrimary whitespace-nowrap'>{t('likeTemplate')}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='col-span-1 lg:col-span-4 w-full flex flex-col items-center justify-start px-3 gap-6'>
          <div className='w-full h-72 bg-blue-200 rounded-md overflow-hidden relative' style={{
            background: "url(https://img.freepik.com/photos-gratuite/vue-avocat-exercice-3d_23-2151023412.jpg?t=st=1727280889~exp=1727284489~hmac=f911f7538a2b6b9fb57709cdee607f8db3788513e4669c182f4d2f608bcfebe0&w=826)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}>
            <div className='absolute inset-0 flex items-center justify-center bg-[rgba(0,0,0,0.4)] '>
              <Link to={"/"} className='px-4 py-2 rounded-md border-2 border-gray-50 text-white'>{t('discoverMore')}</Link>
            </div>
          </div>

          {user && (
            <Link className='w-full px-4 py-3 rounded-md flex items-center justify-center bg-emerald-500 cursor-pointer' to={`/resume/${data?.name}?templateId=${templateID}`}>
              <p className='text-white font-semibold text-lg'>{t('edit')}</p>
            </Link>
          )}

          <div className='w-full flex items-center justify-start flex-wrap gap-2'>
            {data?.tags?.map((tag, index) => (
              <p className='text-xs border border-gray-300 px-2 py-1 rounded-md whitespace-nowrap' key={index}>
                {tag}
              </p>
            ))}
          </div>
        </div>
      </div>

      {templates?.filter((temp) => temp._id !== data?._id).length > 0 && (
        <div className='w-full py-8 flex flex-col items-start justify-start gap-4'>
          <p className='capitalize text-lg font-semibold text-txtDark'>{t('youMightLike')}</p>

          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2'>
            <React.Fragment>
              <AnimatePresence>
                {templates.filter((temp) => temp._id !== data?._id).map((template, index) => (
                  <TemplateDesignPin
                    key={template?._id}
                    data={template}
                    index={index}
                  />
                ))}
              </AnimatePresence>
            </React.Fragment>
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateDesignPinDetail;
