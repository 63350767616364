import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      edit: 'Edit',
      save: 'Save',
      download: 'Download',
      pdf: 'PDF',
      png: 'PNG',
      jpg: 'JPG',
      loginRequired: 'Login required',
      findTemplate: 'Find Your Perfect Resume Template',
      browseCollection: 'Browse our collection of professional resume templates',
      howToUse: 'How to use',
      userPhoto: 'User Photo',
      createTemplate: 'Create Template',
      profile: 'Profile',
      signOut: 'Sign Out',
      auth: 'Login',
      searchHere: 'Search here...',
      resizeImage: 'if your image is not work fix it here',
      somethingWentWrong: 'Something went wrong... Please try later.',
      home: 'Home',
      backHome: 'Back to Home',
      likes: 'likes',
      removeFromCollections: 'Remove from collections',
      addToCollections: 'Add to collections',
      youLikedTemplate: 'You liked this template',
      likeTemplate: 'Like this template',
      discoverMore: 'Discover More',
      youMightLike: 'You might also like'
    }
  },
  ar: {
    translation: {
      edit: 'تعديل',
      save: 'حفظ',
      download: 'تحميل',
      pdf: 'PDF',
      png: 'PNG',
      jpg: 'JPG',
      loginRequired: 'تسجيل الدخول مطلوب',
      findTemplate: 'ابحث عن قالب سيرتك الذاتية المثالي',
      browseCollection: 'تصفح مجموعتنا من قوالب السيرة الذاتية الاحترافية',
      howToUse: 'كيفية الاستخدام',
      userPhoto: 'صورة المستخدم',
      createTemplate: 'إنشاء قالب',
      profile: 'الملف الشخصي',
      signOut: 'تسجيل الخروج',
      auth: 'تسجيل الدخول',
      searchHere: 'ابحث هنا...',
      resizeImage: ' ان لم تعمل صورتك   أصلحها هنا ',
      somethingWentWrong: 'حدث خطأ ما... يرجى المحاولة لاحقاً.',
      home: 'الرئيسية',
      backHome: 'العودة إلى الرئيسية',
      likes: 'إعجابات',
      removeFromCollections: 'إزالة من المجموعات',
      addToCollections: 'إضافة إلى المجموعات',
      youLikedTemplate: 'أعجبك هذا القالب',
      likeTemplate: 'أعجبني هذا القالب',
      discoverMore: 'اكتشف المزيد',
      youMightLike: 'قد يعجبك أيضاً'
    }
  },
  fr: {
    translation: {
      edit: 'Modifier',
      save: 'Enregistrer',
      download: 'Télécharger',
      pdf: 'PDF',
      png: 'PNG',
      jpg: 'JPG',
      loginRequired: 'Connexion requise',
      findTemplate: 'Trouvez votre modèle de CV parfait',
      browseCollection: 'Parcourez notre collection de modèles de CV professionnels',
      howToUse: 'Comment utiliser',
      userPhoto: 'Photo de profil',
      createTemplate: 'Créer un modèle',
      profile: 'Profil',
      signOut: 'Déconnexion',
      auth: 'Connexion',
      searchHere: 'Rechercher ici...',
      resizeImage: 'si ta photo ne fonctionne réglage ici',
      somethingWentWrong: 'Une erreur est survenue... Veuillez réessayer plus tard.',
      home: 'Accueil',
      backHome: 'Retour à l\'accueil',
      likes: 'j\'aime',
      removeFromCollections: 'Retirer des collections',
      addToCollections: 'Ajouter aux collections',
      youLikedTemplate: 'Vous avez aimé ce modèle',
      likeTemplate: 'Aimer ce modèle',
      discoverMore: 'Découvrir plus',
      youMightLike: 'Vous pourriez aussi aimer'
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
